import api_amr from "../services/api";

// Converte arquivos para base64
async function filesToBase64(filesArray) {
    var files = filesArray;
    var fileLocal;

    var aux = []

    for (var i = 0; i < files.length; i++) {

        fileLocal = files[i];
        let base64 = await toBase64(fileLocal)
        aux = [...aux, base64]
    }
    return aux
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

// Valida a permissão do usuário pra acessar a pagina
async function checarPermissao(pagina, props) {
    let permission = ''
    try {
        permission = await api_amr.get('/check_permission', { params: { 'page': pagina }, headers: { 'x-api-key': getToken() } })
    }
    catch (err) {
        alert('Token expirado')
        localStorage.clear()
        props.history.push({ pathname: "/" })
        return false
    }

    if (permission.data === 1) {
        return true

    } else {
        alert('Seu usuário não tem permissão para acessar essa página!')
      
        return false
    }
}

function verificaSenha(senha) {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const passwordLength = senha.length;
    const uppercasePassword = uppercaseRegExp.test(senha);
    const lowercasePassword = lowercaseRegExp.test(senha);
    const digitsPassword = digitsRegExp.test(senha);
    const specialCharPassword = specialCharRegExp.test(senha);
    const minLengthPassword = minLengthRegExp.test(senha);
    let errMsg = [];
    if (passwordLength === 0) {
      errMsg.push("Vazio");
    }
    if (!uppercasePassword) {
      errMsg.push("Precisa ter uma letra maiúscula");
    }
    if (!lowercasePassword) {
      errMsg.push("Precisa ter uma letra minúscula");
    }
    if (!digitsPassword) {
      errMsg.push("Pelo menos um dígito");
    }
    if (!specialCharPassword) {
      errMsg.push("Pelo menos um caracter especial");
    }
    if (!minLengthPassword) {
      errMsg.push("Mínimo 8 caracteres");
    }
    return errMsg
  }


function DataFormatoCerto(data){
    return new Date(data).toISOString().split('T')[0]
}
function getUser() {
    const userString = localStorage.getItem('id')
    if (userString === null) {
        return ''
    } else {
        return userString
    }
}

function getToken() {
    const token = localStorage.getItem('token')
    if (token === null) {
        return ''
    } else {
        return token
    }
}

function getUserInfo() {
    const user = localStorage.getItem('usuario')
    if (user === null) {
        return ''
    } else {
        return user
    }
}

function formatStringUniversalDateToLocal(data) {
    let aux = data.split('-')
    return aux[2] + '-' + aux[1] + '-' + aux[0]
}

// teoricamente pega o ultimo link do sistema que o usuário acessou, mas você tem que setar esse link no localStorage
// então vamos tentar usar isso aqui só como um padrão caso precise salvar um link no localStorage
const getCachedLink = () => {
    const cachedLink = localStorage.getItem('cachedLink')
    if (cachedLink === null) {
        return ''
    } else {
        return cachedLink
    }
}

// deixa a primeira letra da uma string maiuscula
function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}




export {
    filesToBase64,
    checarPermissao,
    getUser,
    getToken,
    formatStringUniversalDateToLocal,
    getUserInfo,
    verificaSenha,
    DataFormatoCerto,
    getCachedLink,
    capitalizeString
}
