import React, { useEffect, useState } from 'react';
import api_amr from '../../services/api.js'
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TextField, Button, Grid, Divider, Typography, Modal, Box, Collapse, Paper, Container
} from '@material-ui/core';


import { checarPermissao, getToken, getUser } from '../../utils/utils'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function CadastroClientes(props) {
    const [nomeCliente, setNomeCliente] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(0);

    const [clientes, setClientes] = useState('')
    const [clientesProspec, setClientesProspec] = useState('')
    const [tab, setTab] = useState(0)
    const [permitido, setPermitido] = useState(false)
    const [edit, setEdit] = useState(0)
    const [editProspec, setEditProspec] = useState(0)


    const [nome_u, setNome_u] = useState('')
    const [cpf_u, setCpf_u] = useState('')
    const [telefone_u, setTelefone_u] = useState('')
    const [email_u, setEmail_u] = useState('')
    const [cidade_u, setCidade_u] = useState('')
    const [comments_u, setComments_u] = useState('')



    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    useEffect(() => {
        async function fetchMyAPI() {
            if (getUser === '') { window.location.replace('http://localhost:3000') }
            setPermitido(await checarPermissao('clientes', props))
            const response = await api_amr.get('/getclientes', {
                headers: { "x-api-key": getToken() }
            });
            setClientes(response.data)
            const response_prospec = await api_amr.get('/return_prospeccao', {
                headers: { "x-api-key": getToken() }
            });
            console.log(response_prospec.data)
            setClientesProspec(response_prospec.data)
        }
        fetchMyAPI()
    }, [])


    const salvar = async () => {
        if ((nomeCliente != '') && (cpfCnpj != '') && (telefone != '') && (email != '')) {
            const obj = {
                documento: cpfCnpj,
                nome: nomeCliente,
                telefone: telefone,
                email: email,
            }

            api_amr.post(`savecadastro`, obj).then((res) => {
                if (res.data.code == 0) {
                    setAlert(1);
                    setTimeout(() => { setAlert(0); }, 4000);
                } else {
                    setAlert(2);
                    setTimeout(() => { setAlert(0); }, 4000);
                }

            }).catch((err) => { })

        } else {
            handleOpen(true);
        }
    }
    const atualizaUsuario = async (id) => {
        const response_cliente = await api_amr.get('/atualiza_cliente', {
            params: { nome_u, cpf_u, telefone_u, email_u, cidade_u, id }
        });
        if (response_cliente.data === 'OK') {
            const response = await api_amr.get('/getclientes', {
                headers: { "x-api-key": getToken() }
            });
            setClientes(response.data)
            window.alert('Cliente atualizado com sucesso')
        }
    }
    const atualizaUsuarioProspec = async (id) => {
        const response_cliente = await api_amr.get('/edita_prospeccao', {
            params: { id, comments_u }
        });
        if (response_cliente.data === 'OK') {
            const response = await api_amr.get('/return_prospeccao', {
                headers: { "x-api-key": getToken() }
            });
            setClientesProspec(response.data)
            window.alert('Cliente atualizado com sucesso')
        }
    }
    const deletaCliente = async (id) => {

        const response = await api_amr.get('/delete_cliente', {
            params: { id }, headers: { "x-api-key": getToken() }
        });
        if (response.data === 'OK') {
            const response = await api_amr.get('/getclientes', {
                headers: { "x-api-key": getToken() }
            });
            setClientes(response.data)
            window.alert('Cliente deletado com sucesso')

        }
        else{
            window.alert('Erro ao deletar cliente')
        }

    }


    const iniciarOrcamento = (cnpjCliente) => {
        window.location.replace(`/admin/GerarOrcamento?cliente=${cnpjCliente}`);
    }

    return (
        permitido ?
            <Container fixed>
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Erro ao salvar cliente
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Informe todos os campos antes de salvar!
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} />
                                <Grid item xs={4} >
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color="error"
                                        size='small'
                                        id="savee"
                                        onClick={async () => {
                                            setOpen(false);
                                        }}
                                    >
                                        OK
                                    </Button>
                                </Grid>
                                <Grid item xs={4} />
                            </Grid>
                        </Box>
                    </Modal>
                </div>
                <Grid item spacing={2}>
                    <Collapse in={alert == 1}>

                    </Collapse>
                    <Collapse in={alert == 2}>

                    </Collapse>

                </Grid>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button onClick={() => setTab(0)}>Cadastro Cliente</Button>
                    <Button onClick={() => setTab(1)}>Clientes Cadastrados</Button>
                    <Button onClick={() => setTab(2)}>Clientes Prospecção</Button>

                </div>
                {tab === 0 ?
                    <div style={{ display: 'flex', flexDirection: 'column', width: '70%', borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: "white", padding: 20, border: '1px solid black' }}>
                        <h2> Cadastrar Cliente</h2>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                id="cpfcnpj"
                                label="Documento do Cliente"
                                defaultValue={cpfCnpj}
                                onChange={e => setCpfCnpj(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField

                                fullWidth
                                required
                                id="nomeCliente"
                                label="Nome Cliente"
                                onChange={e => setNomeCliente(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                id="telefone"
                                label="Telefone"
                                onChange={e => setTelefone(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                required
                                id="email"
                                label="Email"
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <Button
                                fullWidth
                                variant='contained'
                                //color='danger'
                                size='large'
                                id="save"
                                onClick={async () => {
                                    await salvar();
                                }}
                            >
                                Salvar Cliente
                            </Button>
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item xs={12}>
                            <Divider sx={{ marginBottom: 20 }} light />
                        </Grid>
                    </div> : null}
                {tab === 1 ?
                    <div style={{ display: 'flex', flexDirection: 'column', width: '80vw', height: '100vw', borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: "white", padding: 20, border: '1px solid black' }}>
                        <h2> Clientes Cadastrados</h2>
                        {clientes !== '' ?
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell >Nome</TableCell>
                                            <TableCell >CPF/CNPJ</TableCell>
                                            <TableCell >Telefone</TableCell>
                                            <TableCell >Email</TableCell>
                                            <TableCell >Cidade</TableCell>
                                            <TableCell >Gerar Orçamento</TableCell>

                                            <TableCell> Editar </TableCell>
                                            <TableCell> Deletar </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {clientes.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell >
                                                    {edit === row.id ?
                                                        <TextField

                                                            fullWidth
                                                            required
                                                            id="nomeCliente"
                                                            label="Nome Cliente"
                                                            onChange={e => setNome_u(e.target.value)}
                                                        /> : row.nome}
                                                </TableCell>

                                                <TableCell >
                                                    {edit === row.id ?
                                                        <TextField

                                                            fullWidth
                                                            required
                                                            id="nomeCliente"
                                                            label="CPF/CNPJ"
                                                            onChange={e => setCpf_u(e.target.value)}
                                                        /> : row.documento}
                                                </TableCell>
                                                <TableCell >
                                                    {edit === row.id ?
                                                        <TextField

                                                            fullWidth
                                                            required
                                                            id="nomeCliente"
                                                            label="Telefone"
                                                            onChange={e => setTelefone_u(e.target.value)}
                                                        /> : row.telefone}
                                                </TableCell>
                                                <TableCell >
                                                    {edit === row.id ?
                                                        <TextField

                                                            fullWidth
                                                            required
                                                            id="nomeCliente"
                                                            label="Email"
                                                            onChange={e => setEmail_u(e.target.value)}
                                                        /> : row.email}
                                                </TableCell>
                                                <TableCell >
                                                    {edit === row.id ?
                                                        <TextField

                                                            fullWidth
                                                            required
                                                            id="cidade_u"
                                                            label="Cidade"
                                                            onChange={e => setCidade_u(e.target.value)}
                                                        /> : row.cidade}
                                                </TableCell>
                                                <TableCell> <Button onClick={() => iniciarOrcamento(row.documento)}> Gerar Orçamento </Button> </TableCell>

                                                <TableCell>{edit === row.id ? <Button onClick={() => (setEdit(0), atualizaUsuario(row.id))}> Salvar </Button> : <Button onClick={() => setEdit(row.id)}> Editar </Button>}</TableCell>
                                                <TableCell> <Button onClick={() => (deletaCliente(row.id))}> Deletar </Button> </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : null}
                    </div>
                    : null}
                {tab === 2 ?
                    <div style={{ display: 'flex', flexDirection: 'column', width: '70vw', height: '100vw', borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: "white", padding: 20, border: '1px solid black' }}>
                        <h2> Clientes Prospecção</h2>
                        {clientesProspec !== '' ?
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Data</TableCell>
                                            <TableCell >Nome</TableCell>
                                            <TableCell >CPF/CNPJ</TableCell>
                                            <TableCell >Telefone</TableCell>
                                            <TableCell >Email</TableCell>
                                            <TableCell >Comentários</TableCell>
                                            <TableCell> Editar </TableCell>
                                            <TableCell> Gerar Orçamento </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {clientesProspec.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.data}
                                                </TableCell>
                                                <TableCell >{row.nome}</TableCell>
                                                <TableCell >{row.documento}</TableCell>
                                                <TableCell >{row.telefone}</TableCell>
                                                <TableCell >{row.email}</TableCell>
                                                <TableCell > {editProspec === row.id_prospec ?
                                                    <TextField
                                                        multiline
                                                        fullWidth
                                                        required
                                                        id="cidade_u"
                                                        label="Comentários"
                                                        defaultValue={row.comments}
                                                        onChange={e => setComments_u(e.target.value)}
                                                    /> : row.comments}</TableCell>
                                                <TableCell>{editProspec === row.id_prospec ? <Button onClick={() => (setEditProspec(0), atualizaUsuarioProspec(row.id_prospec))}> Salvar </Button> : <Button onClick={() => setEditProspec(row.id_prospec)}> Editar </Button>}</TableCell>
                                                <TableCell> <Button onClick={() => iniciarOrcamento(row.documento)}> Gerar Orçamento </Button> </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : null}
                    </div>
                    : null}
            </Container> : null
    )
}

export default CadastroClientes;