import React, { useState, useEffect } from "react";
import "@fontsource/poppins";
import { Table,TableBody,TableCell,TableContainer,
  TableHead,TableRow,TextField,Button,InputBase,InputAdornment,
  IconButton,CircularProgress,Grid,Divider,Modal,Box,Collapse,Paper ,
  Container,Radio,RadioGroup,FormControlLabel,FormControl,FormLabel,Tooltip} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


import { makeStyles } from "@material-ui/core/styles";

import { setToken } from "../flux/actions/tokenIndex";
import { setUser } from "../flux/actions/userIndex";
import logo from '../assets/img/logo.png'

import api from "../services/api";



function getToken() {
  const token = localStorage.getItem('token')
  if (token === null) {
    return ''
  }
  else {
    return token
  }

}
function getUser() {
  const user = localStorage.getItem('usuario')
  if (user === null) {
    return ''
  }
  else {
    return user
  }
}

function SignInSide(props) {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [logando, setLogando] = useState(false)

  const classes = useStyles();


  useEffect(() => {
    if (getUser() !== '') {

      props.history.push({ pathname: "/admin/GerarOrcamento" })

    }
  }, [])


  async function validateLogin() {

    setLogando(true)

    const response = await api.post('/login',
      { 'login': login, 'password': pass },
    );

    if (response.data === 'NOK1') {
      alert('Usuário ou senha inválidos')
    } else if (response.data === 'NOK') {
      alert('Erro ao realizar login')
    }
    else {
      let token = response.data.token
      localStorage.setItem('token', token)

      const response_info = await api.get('/get_user_info', { params: { 'all': 0 }, headers: { 'x-api-key': token } })
      localStorage.setItem('usuario', JSON.stringify(response_info.data[0]))
      window.location.reload()

    }


    // setLogando(false)
  }

  return (
    <div className={classes.container}>
      <div className={classes.imgDiv}>
        <img src={logo} width='100%' height='50%' style={{marginTop:'25%'}} alt="logo" />
      </div>
      <div className={classes.loginDiv}>



        <div style={{ marginTop: 20, width: '352px' }}>
          <p style={{ fontFamily: 'Poppins', fontSize: '10px' }}> Login</p>
          <TextField
            onChange={(event) => setLogin(event.target.value)}
            placeholder='login@email.com'
            className={classes.textField}
            InputProps={{
              classes: { input: classes.input },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon style={{ color: 'gray', marginLeft: 5 }} />
                </InputAdornment>
              ),
            }}>

          </TextField>

        </div>
        <div style={{ marginTop: 20, width: '352px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '352px' }}>
            <p style={{ fontFamily: 'Poppins', fontSize: '10px' }}> Senha</p>
            {/* <Button autoCapitalize="false" style={{ textTransform: 'none', fontFamily: 'Poppins', fontSize: '10px', marginRight: 0, marginLeft: 'auto', color: '#A3CC51' }}> Esqueceu a senha?</Button> */}
          </div>
          <TextField
            onChange={(event) => setPass(event.target.value)}
            placeholder='*********'
            className={classes.textField}
            type="password"
            InputProps={{
              classes: { input: classes.input },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon style={{ color: 'gray', marginLeft: 5 }} />
                </InputAdornment>
              ),
            }}>

          </TextField>

        </div>
        <div style={{ marginTop: 40, width: '352px', height: 45 }}>
          <Button onClick={() => validateLogin()} variant="contained" style={{ fontFamily: 'Poppins', height: 40, fontSize: '10px', background: 'green', width: '352px', color: 'white', borderRadius: 6 }}> Entrar</Button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>

        </div>
      </div>

    </div >
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row'
  },
  imgDiv: {
    width: '50%',
    height: '100%'
  },
  loginDiv: {
    width: '50%',
    height: '100%',

    alignItems: 'center',
    justifyContent: 'center',

    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    width: '352px',
    backgroundColor: 'white',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItens: 'center',
    borderRadius: 6,

    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
  },
  input: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    width: '352px'
  }
}));



export default SignInSide;
