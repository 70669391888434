import axios from 'axios';
let link = window.location.href
// const url = 'https://back.engiguacu.link:5002/'
let url = ''
if (link.includes('http://localhost:3000/')) {
  url = 'http://localhost:5002/'
  // url = 'https://back2.engiguacu.link:5002/'
}
else {
  url = 'https://back2.engiguacu.link:5002/'
}



const api_amr = axios.create({
  baseURL: url,

});

export default api_amr;
