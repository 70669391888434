
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "./layouts/Admin.js";


import SignInSide from "./siginIn/SignInSide";







const hist = createBrowserHistory();

ReactDOM.render(

  <Router history={hist}>
    <Switch >
      <Route path="/" exact component={SignInSide} />
      {/* <Route path="/Pesquisa/:id/:uid/:token" component={PesquisaGeral} /> */}

      <Route path="/admin" component={Admin} />

    </Switch >
  </Router>
  ,
  document.getElementById("root")
);
