import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import api from '../../services/api'
import Loader from 'react-loader-spinner';
import { checarPermissao, getToken, getUser } from '../../utils/utils'
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TextField, Button, CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

let formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});


function ConfiguracoesGerais(props) {

    const [permitido, setPermitido] = useState(true)
    const [tabelaPreco, setTabelaPreco] = useState('')
    const [marca, setMarca] = useState("");
    const [pot, setPot] = useState("");
    const [numPlaca, setNumPlaca] = useState("");
    const [geracaoTotal, setGeracaoTotal] = useState("");
    const [tipoInv, setTipoInv] = useState("");
    const [valor, setVal] = useState("");
    const [valorSolo, setValSolo] = useState("")
    const [tab, setTab] = useState(0);
    const [tarifas, setTarifas] = useState(0)
    const [edicao, setEdicao] = useState([])
    const [novaMarca, setNovaMarca] = useState('')
    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {
            if (getUser === '') {
                window.location.replace('https://engiguacu.link')
            }
            setPermitido(await checarPermissao('configuracoes_gerais', props))

            const response_tabela_preco = await api.get('/return_tabela_preco', { headers: { 'x-api-key': getToken() } });
            setTabelaPreco(response_tabela_preco.data);

            const vetorEdicao = new Array(response_tabela_preco.data.length).fill(false);
            setEdicao(vetorEdicao);

            const response_tarifas = await api.get('/return_tarifas', { headers: { 'x-api-key': getToken() } });
            setTarifas(response_tarifas.data);

        }
        fetchMyAPI();
    }, []);


    async function cadastraPlaca() {
        if ((marca === '' | pot === '' | numPlaca === '' |
            geracaoTotal === '' | tipoInv === '' | valor === '')) {
            alert('Alguns campos obrigatórios estão incompletos\n' + (marca === '' ? 'Marca\n' : '')
                + (pot === '' ? 'Potência\n' : '') + (geracaoTotal === '' ? 'Geração Placas\n' : '') + (valor === '' ? 'Valor Total\n' : '')
                + (numPlaca === '' ? 'Numero de placas\n' : ''))
            return
        }

        const response = await api.get("/create_preco", {
            params: {
                marca,
                pot,
                num_placas: numPlaca,
                ger_placa: geracaoTotal,
                tipo_inv: tipoInv,
                valor,
                valorSolo
            },
            headers: { "x-api-key": getToken() }
        });




        if (response.data === 'OK') {
            alert('Preço adicionado com sucesso!')
            window.location.reload()
        } else {
            alert('Erro ao inserir placa', response.data)
        }
    }
    async function deletaPlaca(id) {

        const response = await api.get("/delete_preco", {
            params: {
                id,

            },
            headers: { "x-api-key": getToken() }
        });


        if (response.data === 'OK') {
            alert('Placa deletada com sucesso!')
            window.location.reload()
        }
    }
    async function EditarPlaca(id) {
        if (novaMarca !== '') {
            const response = await api.get("/altera_nome_placa", {
                params: {
                    id,
                    'marca': novaMarca
                },
                headers: { "x-api-key": getToken() }
            });


            if (response.data === 'OK') {
                alert('Placa editada com sucesso!')
                setNovaMarca('')
                window.location.reload()
            }
        }
        else {
            return
        }

    }
    async function alter_preco(valor, id, local) {

        const response = await api.get("/alter_preco", {
            params: {
                'ID': id,
                'local': local,
                valor

            },
            headers: { "x-api-key": getToken() }
        });

    }

    // }
    // async function atualizaUsuariobyADM() {
    //     if ((nomeCadastro === '') && (cpfCnpj === '') && (telefone === admUserSelected.cellphone) && (cep === '') && (cidade === '') && (bairroSetor === '') && (end === '') && (senha === '') && (senha2 === '') && (grupo==='') && (seg==='')) {
    //         alert("Nenhum dado foi alterado!")
    //         return
    //     }
    //     if (senha != senha2) {
    //         alert('Senhas diferentes')
    //         return
    //     }
    //     if (!validateCPF(cpfCnpj) && !validateCNPJ(cpfCnpj) && cpfCnpj !== '') {
    //         alert('CPF/CNPJ Inválido')
    //         return
    //     }
    //     if (!validateCEP(cep) && cep !== '') {
    //         alert('CEP Inválido')
    //         return
    //     }

    //     setAtualizando(true)
    //     const response_atualiza_informacoes = await api.post('/update_user', { 'id': (admUserSelected !== null ? admUserSelected.id : ''), 'nome': nomeCadastro, cpfCnpj, telefone, cep, 'id_cidade': (cidade != '' ? cidade.id : ''), 'bairro_setor': bairroSetor, 'endereco': end, 'password': senha, 'grupo':(grupo != '' ? grupo.id : ''), 'seg': (seg != '' ? seg.id : '') }, { headers: { 'x-api-key': getToken() } })
    //     console.log(response_atualiza_informacoes.data)
    //     if ((response_atualiza_informacoes.data === 'OK1')) {
    //         alert('Dados atualizados')
    //         window.location.reload()

    //     } else {
    //         alert('Erro ao atualizar dados')
    //     }
    //     setAtualizando(false)
    // }


    // async function alterSelected(index) {
    //     let aux = Array.from({ length: usersArray.length }, i => i = false)
    //     aux[index] = true
    //     setUsersArray(JSON.parse(JSON.stringify(aux)))
    //     setSelectedUser(index)


    // }
    async function atualizaTarifa(e, id) {

        const response_create = await api.get('/edita_tarifa',
            { params: { 'valor': e, id }, headers: { 'x-api-key': getToken() } });


        console.log(response_create.data)

    }


    return (
        permitido ?
            <div className={classes.container} >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button className={classes.inputh1} onClick={() => setTab(0)}>Tabela de preços</Button>
                    <Button className={classes.inputh1} onClick={() => setTab(1)}>Tarifas</Button>
                </div>
                {tab === 0 ?
                    <div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: '90vw', height: '50vh', overflow: 'auto' }}>

                            {tabelaPreco !== '' ? <TableContainer style={{ display: 'flex', flexDirection: 'column', width: '70%', borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: "white", padding: 20, border: '1px solid black' }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>


                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Marca</b>
                                            </TableCell>
                                            <TableCell width={50}>

                                                <b className={classes.inputh1}>Potência unitária (W)</b>
                                            </TableCell>
                                            <TableCell width={50}>
                                                <b className={classes.inputh1}>Numero de Placas</b>
                                            </TableCell>
                                            <TableCell width={150}>
                                                <b className={classes.inputh1}>Potência Total (kWp)</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Tipo Inversor</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Valor(Telhado)</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Valor(Solo)</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>opções</b>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(tabelaPreco).map((item, index) => (
                                            <TableRow key={index} >

                                                <TableCell>
                                                    {edicao[index] ? (
                                                        // Campo de texto para edição
                                                        <TextField
                                                            defaultValue={tabelaPreco[item].marca}
                                                            onChange={(event) => {
                                                                console.log(event.target.value);
                                                                setNovaMarca(event.target.value);
                                                            }}
                                                        />
                                                    ) : (
                                                        // Somente texto se não estiver em modo de edição
                                                        <b className={classes.inputh1}>{tabelaPreco[item].marca}</b>
                                                    )}
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{tabelaPreco[item].potencia.toString().replace('.', ',')}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{tabelaPreco[item].numero_de_placas}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{tabelaPreco[item].geracao_por_placa.toString().replace('.', ',')}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{tabelaPreco[item].tipo_do_inversor}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <TextField
                                                        type={'number'}
                                                        label={formatter.format(tabelaPreco[item].valor)}
                                                        onChange={(event) => alter_preco(event.target.value, tabelaPreco[item].ID, 'telhado')}
                                                    />

                                                </TableCell>
                                                <TableCell  >
                                                    <TextField
                                                        type={'number'}
                                                        label={formatter.format(tabelaPreco[item].valor_solo)}
                                                        onChange={(event) => alter_preco(event.target.value, tabelaPreco[item].ID, 'solo')}
                                                    />

                                                </TableCell>
                                                <TableCell>
                                                    <Button onClick={() => deletaPlaca(tabelaPreco[item].ID)}>Deleta</Button>
                                                    {!edicao[index] ? (
                                                        <Button onClick={() => {
                                                            // Define todos os valores do vetor de edição para false
                                                            const newEdicao = edicao.map(() => false);
                                                            // Define o valor de edição para true apenas para o item atual
                                                            newEdicao[index] = true;
                                                            setEdicao(newEdicao);
                                                        }}>Editar</Button>
                                                    ) : (
                                                        <Button onClick={() => {
                                                            EditarPlaca(tabelaPreco[item].ID);
                                                            // Após salvar, define o estado de edição de volta para false para todos os itens
                                                            const newEdicao = edicao.map(() => false);
                                                            setEdicao(newEdicao);
                                                        }}>Salvar</Button>
                                                    )}
                                                </TableCell>


                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> : <CircularProgress />}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '70%', borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: "white", padding: 20, border: '1px solid black' }}>
                            <div>
                                {/* <Autocomplete
                                    options={["Marca 1", "Marca 2"]}
                                    value={marca}
                                    onChange={(event, newValue) => setMarca(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Marca" />
                                    )}
                                /> */}
                                <TextField
                                    label="Marca"
                                    value={marca}
                                    onChange={(event) => setMarca(event.target.value)}
                                />
                            </div>

                            <TextField

                                label="Potência unitária (W)"
                                value={pot}
                                onChange={(event) => setPot(event.target.value)}
                            />
                            <TextField
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        step: "any"
                                    }
                                }}
                                label="Número da placas"
                                value={numPlaca}
                                onChange={(event) => setNumPlaca(event.target.value)}
                            />
                            <TextField

                                label="Potência Total (kWp)"
                                value={geracaoTotal}
                                onChange={(event) => setGeracaoTotal(event.target.value)}
                            />
                            <br />
                            {/* <Autocomplete
                                options={["Inv 1", "Inv 2"]}
                                value={tipoInv}
                                onChange={(event, newValue) => setTipoInv(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Tipo de Inversor" />
                                )}
                            /> */}
                            <TextField
                                multiline
                                label="Inversor (Caso sejam dois, descrever um em cada linha)"
                                value={tipoInv}
                                onChange={(event) => setTipoInv(event.target.value)}
                            />
                            <TextField

                                label="Valor Telhado"
                                value={valor}
                                onChange={(event) => setVal(event.target.value)}
                            />
                            <TextField

                                label="Valor Solo"
                                value={valorSolo}
                                onChange={(event) => setValSolo(event.target.value)}
                            />
                        </div>

                        <Button onClick={() => cadastraPlaca()} style={{ background: 'green' }}><p style={{ color: 'white' }}>Adicionar Registro</p></Button>
                    </div > : null}
                {tab === 1 ?
                    <div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: '70%', borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: "white", padding: 20, border: '1px solid black' }}>


                            {tarifas !== '' ? <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>


                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Tipo Tarifa</b>
                                            </TableCell>
                                            <TableCell width={50}>

                                                <b className={classes.inputh1}>Valor Tarifa</b>
                                            </TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(tarifas).map((item, index) => (
                                            <TableRow key={index} >
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{tarifas[item].nome_tarifa}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <TextField
                                                        label="R$/kWh"
                                                        defaultValue={tarifas[item].valor_tarifa.toString().replace('.', ',')}
                                                        onChange={(event) => atualizaTarifa(event.target.value, tarifas[item].id)}
                                                    />
                                                </TableCell>




                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> : <CircularProgress />}

                        </div>

                    </div > : null}
            </div> : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    PhoneInput: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },

    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '90%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
}));

export default ConfiguracoesGerais
