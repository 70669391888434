import React, { useEffect, useState } from 'react';
import api from '../../services/api.js'


import { DataGrid } from '@mui/x-data-grid';
import { Typography, ListItem, withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    TextField, Button, Grid, Divider, Box,
    Container, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Tooltip
} from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

import { checarPermissao, getUser, getToken } from '../../utils/utils'
import Autocomplete from '@material-ui/lab/Autocomplete';



const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);


function GerarOrcamento(props) {
    const [userExists, setuserExists] = useState(0);
    const [nomeCliente, setNomeCliente] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [cep, setCep] = useState('');
    const [uf, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [tipoOrcamento, setTipoOrcamento] = useState(0);
    const [tipoUnidadeConsumidora, setTipoUnidadeConsumidora] = useState(0);
    const [localUsina, setLocalUsina] = useState(0);
    const [posicaoTelhado, setPosicaoTelhado] = useState(0);
    const [valorTarifa, setvalorTarifa] = useState(0);
    const [padraoEntrada, setPadraoEntrada] = useState(0);
    const [geracaoDesejada, setGeracaoDesejada] = useState(0);
    const [currentFile, setcurrentFile] = useState('');
    const [progress, setProgress] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState(false);
    const [fileInfos, setfileInfos] = useState([]);
    const [alert, setAlert] = useState(0);
    const [boards, setBoards] = useState([]);
    const [selectedBoard, setSelectedBoard] = useState(999999);
    const [selectedPrice, setSelectedPrice] = useState(999999);

    const [enviarBack, setEnviarBack] = useState([])
    const [permitido, setPermitido] = useState(false)
    const [id, setId] = useState(-1)
    const [filial, setFilial] = useState('');
    const [prospeccao, setProspeccao] = useState('')
    const [numShow, setNumShow] = useState(3)
    const [obs, setObservacoes] = useState('')
    const [gerando, setGerando] = useState(false)
    const [rows, setRows] = React.useState(() => [
        {
            id: 1,
            mes: '1',
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            media: 0
        },
        {
            id: 2,
            mes: '2',
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            media: 0
        },
        {
            id: 3,
            mes: '3',
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            media: 0
        },
        {
            id: 4,
            mes: '4',
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            media: 0
        },
        {
            id: 5,
            mes: '5',
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            media: 0
        },
        {
            id: 6,
            mes: '6',
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            media: 0
        },
        {
            id: 7,
            mes: '7',
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            media: 0
        }
    ]);



    useEffect(() => {

        if (getUser === '') { window.location.replace('https://engiguacu.link') }
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('gerar_orcamento', props))

        }
        const queryParams = new URLSearchParams(window.location.search);
        const cliente = queryParams.get("cliente");
        setCpfCnpj(cliente)
        loadCli(cliente)
        fetchMyAPI()

    }, [])

    const options = [
        { title: '1-Filial Pato Branco' },
        { title: '2-Filial Laranjeiras do Sul' },
        { title: '3-Filial Chopinzinho' },
    ];


    var columns = [
        { field: 'mes', headerName: 'UC', width: 120, type: 'string', editable: true },
        { field: 'jan', headerName: 'Jan', width: 100, type: 'number', editable: true },
        { field: 'fev', headerName: 'Fev', width: 100, type: 'number', editable: true },
        { field: 'mar', headerName: 'Mar', width: 110, type: 'number', editable: true },
        { field: 'abr', headerName: 'Abr', width: 110, type: 'number', editable: true },
        { field: 'mai', headerName: 'Mai', width: 110, type: 'number', editable: true },
        { field: 'jun', headerName: 'Jun', width: 110, type: 'number', editable: true },
        { field: 'jul', headerName: 'Jul', width: 110, type: 'number', editable: true },
        { field: 'ago', headerName: 'Ago', width: 110, type: 'number', editable: true },
        { field: 'set', headerName: 'Set', width: 110, type: 'number', editable: true },
        { field: 'out', headerName: 'Out', width: 110, type: 'number', editable: true },
        { field: 'nov', headerName: 'Nov', width: 110, type: 'number', editable: true },
        { field: 'dez', headerName: 'Dez', width: 110, type: 'number', editable: true },
        { field: 'media', headerName: 'Média kWh', width: 170, type: 'number', editable: false, cellClassName: 'super-app-theme--cell' },
    ];





    const loadCli = (cliente = '') => {
        console.log(cliente)
        if (cliente !== '') {

            api.get(`getclient`, {
                params: { 'cliente':cliente },
                headers: { "x-api-key": getToken() }
            }).then((res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                    setuserExists(1)
                    setNomeCliente(res.data.name);
                    setId(res.data.id)
                    getTarifa();
                } else {
                    setuserExists(2)
                    setNomeCliente('')
                    setTelefone('')
                    setEmail('')
                    setCep('')
                    setEstado('')
                    setCidade('')
                }
            }).catch((err) => {
                console.error(err)
            });
            return
        }
        api.get(`getclient`, {
            params: { 'cliente':cpfCnpj },
            headers: { "x-api-key": getToken() }
        }).then((res) => {
            console.log(res.data);
            if (res.data.code == 0) {
                setuserExists(1)
                setNomeCliente(res.data.name);
                setId(res.data.id)
                getTarifa();
            } else {
                setuserExists(2)
                setNomeCliente('')
                setTelefone('')
                setEmail('')
                setCep('')
                setEstado('')
                setCidade('')
            }
        }).catch((err) => {
            console.error(err)
        });
    }
    const preCad = () => {
        if (nomeCliente === '') {
            window.alert('Preecha os campos do pré cadastro')
            return
        }
        const obj = {
            documento: cpfCnpj,
            nome: nomeCliente,
            telefone: telefone,
            email: email,
            cidade: cidade,
            uf: uf,
            cep: cep
        }

        api.post(`saveprecadastro`, obj).then((res) => {
            setAlert(1);
            setTimeout(() => { setAlert(0); }, 4000);

            loadCli();

        }).catch((err) => {
            setAlert(2);
            setTimeout(() => { setAlert(0); }, 4000);
        })
    };

    const getTarifa = (value) => {
        api.get(`gettarifa/${value || 0}`).then((res) => {
            console.log(res.data);
            if (res.data.code == 0) {
                setvalorTarifa(res.data.valor || 0)
            } else {
                setvalorTarifa(0)
                setAlert(3);
                setTimeout(() => { setAlert(0); }, 4000);
            }
        }).catch((err) => {
            console.error(err)
        });
    }

    const getPlacas = async (p, v) => {
       
        setBoards([]);
        if (geracaoDesejada > 0) {
            const response = await api.get('/getplacas', { params: { posicao: p, geracaoDesejada: v }, headers: { 'x-api-key': getToken() } });
            setBoards(response.data)
            console.log('placas', response.data)
        }
    }

    const downloadDoc = async (id) => {

        window.open(`https://back2.engiguacu.link:5002/gerapdf/${id}`);


    }

    const [images, setImages] = useState([]);

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const imagesData = [];

        files.forEach(file => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const imageData = {
                    name: file.name,
                    data: reader.result
                };

                imagesData.push(imageData);

                if (imagesData.length === files.length) {
                    setImages([...images, ...imagesData]);
                }
            };

            reader.readAsDataURL(file);
        });
        console.log(images)
    };

    const salvaOrcamento = async () => {
        setGerando(true)
        console.log(selectedBoard)
        if (cep === '' | cidade === '' | uf === '' | cpfCnpj === '' | selectedBoard === 999999 | filial === '') {
            window.alert('Campos não preenchidos!')
            setGerando(false)
            return
        }
        const imageArray = images.map(image => image.data);
        const imageString = imageArray.join('divisaoMaster');
        const obj = {
            cep: cep,
            cidade: cidade,
            uf: uf,
            documento: cpfCnpj,
            tarifa: tipoUnidadeConsumidora,
            padraoEntrada: padraoEntrada,
            posicaoTelhado: posicaoTelhado,
            localUsina: localUsina,
            geracaoDesejada: geracaoDesejada,
            tipoUnidadeConsumidora: tipoUnidadeConsumidora,
            faturas: {
                fat1: enviarBack[1], fat2: enviarBack[2], fat3: enviarBack[3],
                fat4: enviarBack[4], fat5: enviarBack[5], fat6: enviarBack[6], fat7: enviarBack[7]
            },
            selectedBoard: selectedBoard, filial, tipoOrcamento, obs, price:selectedPrice

        }
        const respose = await api.get(`salvaorcamento`, { params: obj, headers: { 'x-api-key': getToken() } })
        console.log(respose)
        if (respose.data.code === 1) {
            imageArray.forEach(async function (image) {
                const respose_upload = await api.get(`upload`, { params: { image, 'id': respose.data.id }, headers: { 'x-api-key': getToken() } })
                console.log(respose_upload)
            })

        }
        if (respose.data.code === 1) {
            if (tipoOrcamento === 0) { await downloadDoc(respose.data.id) }
            window.alert('Orçamento Salvo com Sucesso!');
            window.location.reload()

        } else {
            window.alert('Erro ao gerar orçamento')
            setGerando(false)
        }
        setGerando(false)
    }

    const GenereteDoc = () => { }

    const setGeracaoDesejadaEx = (value) => {
        setGeracaoDesejada(value);
        getPlacas(posicaoTelhado, value)
    }

    const changeTipoTarifa = (value) => {
        setTipoUnidadeConsumidora(value);
        getTarifa(value);
    }
    const changeLocalUsina = (value) =>{
        setLocalUsina(value);
    }

    const changePadraoEntreda = (value) => {
        setPadraoEntrada(value);
    }

    const changeTipoOrcamento = (value) => {
        setTipoOrcamento(value);
    }

    const changePosicaoTelhado = (value) => {
        setPosicaoTelhado(value);
        getPlacas(value, geracaoDesejada);
    }

    const selectFile = (event) => {
        console.log(event);
        setSelectedFiles(event.target.files);
    }

    const upload = (event) => {
        let currentFile = selectedFiles[0];

        fileInfos.push(currentFile);
    }

    const setaEnvioBackEGeracaoMedia = (dados) => {
        if (JSON.stringify(dados) === JSON.stringify(enviarBack)) {
            return
        }
        setGeracaoDesejadaEx(calculateAllMeans(dados))
        setEnviarBack(dados)
    }
    function calculateAllMeans(data) {
        const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
        let totalMean = 0;
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const element = data[key];
                let mean = 0;
                for (const month of months) {
                    mean += element[month];
                }
                mean = mean / months.length;
                element.media = mean;
                totalMean += mean;
            }
        }
        return Math.round(totalMean, 2);
    }


    async function save_prospeccao() {

        const response = await api.get("/save_prospeccao", {
            params: {
                id,
                prospeccao,
            },
            headers: { "x-api-key": getToken() }
        });

        if (response.data === 'OK') {
            window.alert('Cliente de prospecção cadastrado com sucesso!')
            window.location.reload()
        } else {
            window.alert('Erro ao cadastrar cliente de prospecção')
        }


    }
    return (
        permitido ?
            <Container fixed>
                <Grid container spacing={2}>

                    <Grid item xs={12} >
                        <FormControl
                            fullWidth                 >
                            <FormLabel id="demo-row-radio-buttons-group-label">Tipo de Orçamento</FormLabel>
                            <RadioGroup
                                value={tipoOrcamento}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <Grid item xs={4} >
                                    <Tooltip title="Gera PDF do orçamento no final da operação">
                                        <FormControlLabel value="0" control={<Radio checked={tipoOrcamento === 0} />} label="Automático" onClick={() => changeTipoOrcamento(0)} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={4}>
                                    <Tooltip title="Não gera PDF e envia orçamento para validação, obrigatório upload de fotos do telhado">
                                        <FormControlLabel value="1" control={<Radio checked={tipoOrcamento === 1} />} label="Manual " onClick={() => changeTipoOrcamento(1)} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={4} >
                                    <Tooltip title="Só registra cliente como de prospeção">
                                        <FormControlLabel value="2" control={<Radio checked={tipoOrcamento === 2} />} label="Cliente Prospecção" onClick={() => changeTipoOrcamento(2)} />
                                    </Tooltip>
                                </Grid>

                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            required
                            id="cpfcnpj"
                            label="Documento do Cliente"
                            defaultValue={cpfCnpj}
                            onBlur={b => loadCli()}
                            onChange={e => setCpfCnpj(e.target.value)}
                        />
                    </Grid>
                    {userExists == 1 &&
                        <Grid item xs={8}>
                            <TextField
                                disabled
                                fullWidth
                                required
                                id="nomeCliente"
                                label="Nome Cliente"
                                value={nomeCliente}
                            />
                        </Grid>
                    }
                    {userExists == 2 &&
                        <Grid item xs={8}>
                            <TextField

                                fullWidth
                                required
                                id="nomeCliente"
                                label="Nome Cliente"
                                onChange={e => setNomeCliente(e.target.value)}
                            />
                        </Grid>
                    }
                    {userExists > 1 &&
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                id="telefone"
                                label="Telefone"
                                onChange={e => setTelefone(e.target.value)}
                            />
                        </Grid>
                    }
                    {userExists > 1 &&
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                required
                                id="email"
                                label="Email"
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Grid>
                    }
                    {userExists > 1 &&

                        <Grid
                            item>
                            <Button
                                variant='contained'
                                color='success'
                                size='large'
                                id="cadcli"
                                onClick={() => {
                                    preCad();
                                }}
                            >
                                Salvar pré cadastro
                            </Button>
                        </Grid>
                    }
                </Grid>
                {((userExists === 1) & ((tipoOrcamento === 2))) ?
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                id="prospec"
                                label="Comentários"
                                onChange={e => setProspeccao(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => save_prospeccao(prospeccao)}> Registra Cliente Prospecção</Button>
                        </Grid>
                    </Grid> : null}
                {((userExists === 1) & ((tipoOrcamento === 1))) ?
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                id="coments"
                                label="Observações"
                                onChange={e => setObservacoes(e.target.value)}
                            />
                        </Grid>

                    </Grid> : null}

                {(userExists == 1) && (tipoOrcamento !== 2) &&
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        </Grid>


                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                required
                                id="cep"
                                label="CEP"
                                onChange={e => setCep(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                fullWidth
                                required
                                id="cidade"
                                label="Cidade"
                                onChange={e => setCidade(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                required
                                id="uf"
                                label="UF"
                                onChange={e => setEstado(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                id="filial-autocomplete"
                                options={options}
                                getOptionLabel={(option) => option.title}
                                style={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Selecione a Filial"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(event, newValue) => {
                                    setFilial(newValue ? newValue.title : '');
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled
                                label="Valor por kw/h"
                                value={valorTarifa}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControl
                                fullWidth                 >
                                <FormLabel id="demo-row-radio-buttons-group-label">Tipo de unidade consumidora</FormLabel>
                                <RadioGroup
                                    value={tipoUnidadeConsumidora}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                //onChange={ getTarifa } 
                                // onBlur={ changeTipoTarifa } 
                                >
                                    <Grid item xs={6} >
                                        <Tooltip title="Rural">
                                            <FormControlLabel value="0" control={<Radio checked={tipoUnidadeConsumidora === 0} />} onClick={() => changeTipoTarifa(0)} label="Rural" />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip title="Urbana">
                                            <FormControlLabel value="1" control={<Radio checked={tipoUnidadeConsumidora === 1} />} onClick={() => changeTipoTarifa(1)} label="Urbana" />
                                        </Tooltip>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                            <FormControl
                                fullWidth                 >
                                <FormLabel id="demo-row-radio-buttons-group-label">Local da Usina</FormLabel>
                                <RadioGroup
                                    value={localUsina}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                //onChange={ getTarifa } 
                                // onBlur={ changeTipoTarifa } 
                                >
                                    <Grid item xs={6} >
                                        <Tooltip title="Telhado">
                                            <FormControlLabel value="0" control={<Radio checked={localUsina === 0} />} onClick={() => changeLocalUsina(0)} label="Telhado" />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip title="Solo">
                                            <FormControlLabel value="1" control={<Radio checked={localUsina === 1} />} onClick={() => changeLocalUsina(1)} label="Solo" />
                                        </Tooltip>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} >
                            <FormControl
                                fullWidth                 >
                                <FormLabel id="radio-buttons-padrao-entrada">Padrão de entrada</FormLabel>
                                <RadioGroup
                                    value={padraoEntrada}
                                    row
                                    aria-labelledby="radio-buttons-padrao-entrada"
                                    name="row-radio-buttons-group-padrao-entrada"
                                // onChange={ changePadraoEntreda } 
                                >
                                    <Grid item xs={4} >
                                        <Tooltip title="Monofásico">
                                            <FormControlLabel value="0" control={<Radio checked={padraoEntrada === 0} />} label="Monofásico" onClick={() => changePadraoEntreda(0)} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Tooltip title="Bifásico">
                                            <FormControlLabel value="1" control={<Radio checked={padraoEntrada === 1} />} label="Bifásico" onClick={() => changePadraoEntreda(1)} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Tooltip title="Trifásico">
                                            <FormControlLabel value="2" control={<Radio checked={padraoEntrada === 2} />} label="Trifásico" onClick={() => changePadraoEntreda(2)} />
                                        </Tooltip>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                            <FormControl
                                fullWidth                 >
                                <FormLabel id="radio-buttons-padrao-entrada">Posição do Telhado</FormLabel>
                                <RadioGroup
                                    value={posicaoTelhado}
                                    row
                                    aria-labelledby="radio-buttons-padrao-entrada"
                                    name="row-radio-buttons-group-padrao-entrada"
                                // onChange={ changePadraoEntreda } 
                                >
                                    <Grid item xs={4} >
                                        <Tooltip title="Telhado apontado para o norte">
                                            <FormControlLabel value="0" control={<Radio checked={posicaoTelhado === 0} />} label="Norte" onClick={() => changePosicaoTelhado(0)} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Tooltip title="Telhado apontado para nordeste ou noroeste">
                                            <FormControlLabel value="1" control={<Radio checked={posicaoTelhado === 1} />} label="Nordeste/Noroeste" onClick={() => changePosicaoTelhado(1)} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Tooltip title="Telhado apontado para o leste ou oeste">
                                            <FormControlLabel value="2" control={<Radio checked={posicaoTelhado === 2} />} label="Leste/Oeste" onClick={() => changePosicaoTelhado(2)} />
                                        </Tooltip>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>

                            {/* <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                        <Button size="small" onClick={handleAddRow}>
                            Adiciona Fatura
                        </Button>
                        <Button size="small" onClick={handleDeleteRow}>
                             Remove Fatura
                        </Button>
                    </Stack> */}
                            <Box
                            >
                                <DataGrid
                                    autoHeight
                                    rows={rows}
                                    columnBuffer={13}
                                    columns={columns}
                                    pageSize={4}
                                    experimentalFeatures={{ newEditingApi: true }}
                                    onStateChange={(state) => setaEnvioBackEGeracaoMedia(state.rows.idRowsLookup)}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Geração desejada"
                                value={geracaoDesejada}
                                onChange={e => setGeracaoDesejadaEx(e.target.value)}
                            />
                        </Grid>
                        {boards.length > 0 && (
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Opções</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={selectedBoard}
                                        name="radio-buttons-group"
                                    >
                                        {boards.length > 0 && boards.map((obj, index) => (
                                            <FormControlLabel value={obj.ID} control={<Radio checked={selectedBoard === obj.ID} />} label={`${obj.numero_de_placas} - ${obj.marca} (${obj.ger.toFixed(2)} kWh/mês) - R$ ${localUsina === 0 ? obj.valor.toFixed(2):obj.valor_solo.toFixed(2)}`} onClick={() => (setSelectedBoard(obj.ID),setSelectedPrice(localUsina === 0 ? obj.valor.toFixed(2):obj.valor_solo.toFixed(2)))} />

                                        ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        )}
                        {((tipoOrcamento === 0) | (tipoOrcamento === 1)) ?
                            <Grid item xs={12}>
                                Fotos (Telhado, entrada, posição, etc...)
                                <input type="file" multiple onChange={handleImageChange} />
                                <div>
                                    {images.map((image, index) => (
                                        <img style={{ width: 240, height: 240 }} src={image.data} key={index} alt={image.name} />
                                    ))}
                                </div>
                            </Grid> : null
                        }
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            {gerando ? <CircularProgress /> :
                                <Button

                                    fullWidth
                                    variant='contained'
                                    //color='danger'
                                    size='large'
                                    id="save"
                                    onClick={() => {
                                        (salvaOrcamento())
                                    }}
                                >
                                    Salvar Orçamento
                                </Button>}
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item xs={12}>
                            <Divider sx={{ marginBottom: 20 }} light />
                        </Grid>
                    </Grid>
                }

            </Container> : null
    );
}

export default GerarOrcamento;