import React, { useState, useEffect } from "react";
import api from '../../services/api.js'
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
    input: {
        marginBottom: 16,
    },
});

const Estoques = () => {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [operations, setOperations] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [quantity, setQuantity] = useState("");
    const [item, setItem] = useState('');
    const [operationType, setOperationType] = useState(null);
    const [search, setSearch] = useState("");
    const [localidade, setLocalidade] = useState("");
    const [localidadeOperation, setLocalidadeOperation] = useState("");
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [itemToDelete, setItemToDelete] = React.useState(null);

    useEffect(() => {
        fetchItems();
        fetchOperations();
    }, []);

    const filteredItems = items.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
    );

    const fetchItems = async () => {
        const response = await api.get("/item/list");
        setItems(response.data);
    };

    const fetchOperations = async () => {
        const response = await api.get("/stock_operations");
        setOperations(response.data);
    };

    const createItem = async () => {
        if (!item || !localidade) return;
        await api.post("/item/create", { name: item, localidade });
        window.alert('Item Criado com Sucesso')
        fetchItems();
    };

    const handleOperation = async () => {
        if (!selectedItem || !operationType || !localidadeOperation) {
            window.alert(selectedItem, operationType, localidadeOperation)
        };
        const endpoint = operationType === "Inserir" ? "/item/add" : "/item/remove";
        await api.put(endpoint, { name: selectedItem, quantity, localidade: localidadeOperation });

        setSelectedItem(null);
        setQuantity("");
        setLocalidadeOperation("");
        setOperationType(null);
        fetchItems();
        fetchOperations();
    };


    const handleDelete = async () => {
        try {

            await api.get('/item/delete', { params: { name: itemToDelete.name, localidade: itemToDelete.localidade } });
            // Refresh the items after delete, assuming you have a function to fetch items
            fetchItems();
        } catch (error) {
            console.error('Erro ao deletar item:', error);
        }
        setItemToDelete(null);
        setDeleteDialogOpen(false);
    };

    const openDeleteDialog = (item) => {
        setItemToDelete(item);
        setDeleteDialogOpen(true);
    };

    return (
        <div>
            <h1>Controle de Estoque</h1>

            <TextField
                label="Nome Item"
                value={item}
                onChange={(e) => setItem(e.target.value)}
                style={{ border: '1px solid black', borderRadius: 10, textAlign: 'center' }}
            />
            <TextField
                label="Localidade"
                value={localidade}
                onChange={(e) => setLocalidade(e.target.value)}
                style={{ border: '1px solid black', borderRadius: 10, marginLeft: 10, textAlign: 'center' }}
            />
            <Button onClick={createItem}>Criar Item</Button>

            <h2>Operações Estoque</h2>
            <Box display="flex">
                <Autocomplete
                    className={classes.input}
                    options={items.map((item) => item.name)}
                    value={selectedItem}
                    onChange={(event, newValue) => setSelectedItem(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} label="Item name" variant="outlined" />
                    )}
                    style = {{width:250}}
                />
                {selectedItem !== '' ? (
                    <Autocomplete
                        className={classes.input}
                        options={items
                            .filter((item) => item.name === selectedItem)
                            .map((item) => item.localidade)}
                        value={localidadeOperation}
                        onChange={(event, newValue) => setLocalidadeOperation(newValue)}
                        onInputChange={(event, inputValue) => setLocalidadeOperation(inputValue)}
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} label="Localidade" variant="outlined" />
                        )}
                        style = {{width:250}}
                    />
                ) : (
                    <CircularProgress />
                )}
                <Autocomplete
                    className={classes.input}
                    options={["Inserir", "Remover"]}
                    value={operationType}
                    onChange={(event, newValue) => setOperationType(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} label="Operation Type" variant="outlined" />
                    )}
                    style = {{width:250}}
                />
                <TextField
                    className={classes.input}
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    variant="outlined"
                    style = {{width:250}}
                />
            </Box>

            <button onClick={handleOperation}>Últimas Operações</button>

            <h2>Items</h2>
            <TextField
                label="Filtrar"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
                className={classes.input}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell align="right">Quantidade</TableCell>
                            <TableCell align="right">Localidade</TableCell>
                            <TableCell align="right">Ação</TableCell> {/* Add this line for the action column header */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredItems.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell component="th" scope="row">
                                    {item.name}
                                </TableCell>
                                <TableCell align="right">{item.quantity}</TableCell>
                                <TableCell align="right">{item.localidade}</TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => openDeleteDialog(item)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>



            <h2>Stock Operations</h2>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome Item</TableCell>
                            <TableCell>Operação</TableCell>
                            <TableCell align="right">Quantidade</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {operations.map((operation) => (
                            <TableRow key={operation.id}>
                                <TableCell component="th" scope="row">
                                    {operation.item_name}
                                </TableCell>
                                <TableCell>{operation.operation}</TableCell>
                                <TableCell align="right">{operation.quantity}</TableCell>
                                <TableCell align="right">{operation.localidade}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Item</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {itemToDelete && itemToDelete.quantity !== 0
                            ? 'Este item tem uma quantidade não zero, ainda sim quer deletar?'
                            : 'Confirmar Delete do Item/Localidade'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    );
};

export default Estoques;