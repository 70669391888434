import React, { useEffect, useState } from 'react';
import api from '../../services/api.js'

import {
    TableCell, Button,
    IconButton, Grid,
    Chip, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Tooltip
} from '@material-ui/core';


import DeleteIcon from '@material-ui/icons/Delete';

import Autocomplete from '@material-ui/lab/Autocomplete';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { checarPermissao, getUser, getToken } from '../../utils/utils'
import { BrushRounded } from '@material-ui/icons';
let formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

function GerenciaOrcamentos(props) {
    const [rows, setRows] = React.useState([])
    const [orcamentos, setOrcamentos] = useState('')
    const [permitido, setPermitido] = useState(false)
    const [edit, setEdit] = useState(-1)
    const [posicaoTelhado, setPosicaoTelhado] = useState('');
    const [boards, setBoards] = useState([]);
    const [geracaoDesejada, setGeracaoDesejada] = useState(0)
    const [padraoEntrada, setPadraoEntrada] = useState(0)
    const [orcamentosFiltrados, setOrcamentoFiltrados] = useState('')
    const [nomeFilter, setNomeFilter] = useState(null);
    const [nameFilter, setNameFilter] = useState(null);
    const [filialFilter, setFilialFilter] = useState(null);
    const [selectedBoard, setSelectedBoard] = useState(999999)
    const [arquivos, setArquivos] = useState('')
    const [selectedPrice, setSelectedPrice] = useState(0)
    const [atualizando, setAtualizando] = useState(false)
    const [localUsina, setLocalUsina] = useState('')

    useEffect(() => {

        if (getUser === '') { window.location.replace('https://engiguacu.link') }
        async function fetchMyAPI() {
            setPermitido(await checarPermissao('orcamentos', props))

            const response = await api.get('/getorcamentos');
            // console.log('orca', response.data)
            setOrcamentoFiltrados(response.data)

            setOrcamentos(response.data)

        }
        fetchMyAPI()

    }, [])
    const extractUniqueValues = (field) => {
        return Array.from(new Set(orcamentos.map((orcamento) => orcamento[field])))

    };

    const baixarOrcamento = (id, num_rev) => {

        window.open(`https://back2.engiguacu.link:5002/geradoc/${id}?rev=${num_rev}`);

    }
    const baixarOrcamentoPdf = (id, num_rev) => {

        window.open(`https://back2.engiguacu.link:5002/gerapdf/${id}?rev=${num_rev}`);

    }
    const deleteIcon = (
        <IconButton sx={{ padding: 0 }}>
            <DeleteIcon fontSize="small" color="error" />
        </IconButton>
    );
    const applyFilters = () => {
        const filteredOrcamentos = orcamentos.filter((orcamento) => {
            return (
                (nomeFilter === null || nomeFilter === '' || orcamento.nome === nomeFilter) &&
                (nameFilter === null || nameFilter === '' || orcamento.name === nameFilter) &&
                (filialFilter === null || filialFilter === '' || orcamento.filial === filialFilter)
            );
        });
        setOrcamentoFiltrados(filteredOrcamentos);
    };




    const getPlacas = async (v) => {
        setBoards([]);
        if (geracaoDesejada > 0) {
            const response = await api.get('/getplacas', { params: { posicao: posicaoTelhado, geracaoDesejada: v }, headers: { 'x-api-key': getToken() } });

            setBoards(response.data)
            console.log('placas', response.data)
        }
    }

    const atualizaOrcamento = async (orcamento) => {
        console.log('teste', posicaoTelhado, localUsina)
        const aux = orcamento.posicao === 'Norte' ? 0 : (orcamento.posicao === 'Nordeste/Noroeste' ? 1 : 2)
        if (selectedBoard === 999999) {
            window.alert('Selecione uma placa')
            return
        }
        if (posicaoTelhado === aux && localUsina === orcamento.local_usina) {

            window.alert('Faça alguma edição antes de salvar')
            return
        }
        setAtualizando(true)
        const obj = {
            'geracao_atualizada': geracaoDesejada,
            'placas_atualizada': selectedBoard,
            'id_orcamento_pai': orcamento.id,
            'posicaoTelhado': posicaoTelhado,
            'price': selectedPrice,
            'localUsina': localUsina
        }
        // console.log('salvando',obj)
        const respose = await api.get(`atualizaOrcamento`, { params: obj, headers: { 'x-api-key': getToken() } })
        // console.log(respose)
        if (respose.data === 'OK') {
            window.location.reload()
            // const response = await api.get('/getorcamentos');            
            // setOrcamentos(response.data)
            alert('Orcamento Atualizado com sucesso')

        } else {

            alert('erro ao editar orçamento')
        }
        setAtualizando(false)
        setEdit(-1)
    }
    const changePadraoEntreda = (value) => {
        setPadraoEntrada(value);
    }
    const getArquivos = async (id) => {

        const response = await api.get('/return_arquivos_orcamentos', { params: { id }, headers: { 'x-api-key': getToken() } });
        setArquivos(response.data)
        if (response.data.length === 0) {
            alert('Orçamento não possui fotos')
        }

    }
    const deletaOrcamento = async (id) => {

        const response = await api.get('/deleta_orcamento', { params: { id }, headers: { 'x-api-key': getToken() } });

        if (response.data === 'OK') {
            alert('Orçamento deletado com sucesso')
            window.location.reload()
        } else {
            alert('Erro ao deletar orçamento')
        }

    }


    const setGeracaoDesejadaEx = (value, index) => {
        // console.log('val', value)
        setGeracaoDesejada(value);
        getPlacas(value)
    }
    const changeLocalUsina = (value) => {
        setLocalUsina(value);
    }
    const handleEditOrcamento = (orcamento) => {
        getPlacas(orcamento.geracao_desejada)
        console.log('orc', orcamento)
        console.log('asdasd',getPlacaValor(orcamento.placas_id, orcamento.local_usina))
        setEdit(orcamento.id)
        setArquivos('')
        setSelectedBoard(orcamento.placas_id)
        setGeracaoDesejadaEx(orcamento.geracao_desejada)
        setLocalUsina(orcamento.local_usina)
        setPosicaoTelhado(orcamento.posicao === 'Norte' ? 0 : (orcamento.posicao === 'Nordeste/Noroeste' ? 1 : 2))
        setSelectedPrice(getPlacaValor(orcamento.placas_id, orcamento.local_usina))
    }
    const getPlacaValor = (id_placa, local) => {
        // Procurando a placa pelo ID fornecido
        const placa = boards.find(placa => placa.ID === id_placa);
        if (!placa) {
            return 0; // Caso o ID não seja encontrado
        }

        // Retorna valor ou valor_solo com base no local
        console.log('res', local === 0 ? placa.valor : placa.valor_solo)
        return local === 0 ? placa.valor : placa.valor_solo;
    }

    return (
        permitido ?
            <div style={{ display: 'flex', flexDirection: 'column', width: '90%', height: '80vw', borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: "white", padding: 20, border: '1px solid black' }}>
                {/* <Alert severity="error">Página em construção</Alert> */}
                <h2>Orçamentos</h2>
                <Grid div spacing={2}>
                    {orcamentos !== '' ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Autocomplete
                            value={nomeFilter}
                            onChange={(event, newValue) => {
                                setNomeFilter(newValue);
                            }}
                            options={extractUniqueValues('nome')}
                            renderInput={(params) => <TextField {...params} label="Cliente" />}
                        />
                        <Autocomplete
                            value={nameFilter}
                            onChange={(event, newValue) => {
                                setNameFilter(newValue);
                            }}
                            options={extractUniqueValues('name')}
                            renderInput={(params) => <TextField {...params} label="Vendedor" />}
                        />
                        <Autocomplete
                            value={filialFilter}
                            onChange={(event, newValue) => {
                                setFilialFilter(newValue);
                            }}
                            options={extractUniqueValues('filial')}
                            renderInput={(params) => <TextField {...params} label="Filial" />}
                        />
                        <Button onClick={() => applyFilters()} variant="contained" color="primary">
                            Filtrar
                        </Button>

                    </div> : null}
                    {orcamentos !== '' ?
                        Object.keys(orcamentosFiltrados).map((index) =>
                            <Accordion style={{ width: '100%' }} key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={index}
                                >
                                    <Typography >
                                        Cliente:{orcamentosFiltrados[index].nome} -
                                        Vendedor:{orcamentosFiltrados[index].name} -
                                        Filial:{orcamentosFiltrados[index].filial} -
                                        Tipo: {orcamentosFiltrados[index].tipo_orcamento === 0 ? 'Automático' : 'Manual'} -
                                        Data Criação : {orcamentosFiltrados[index].data_criacao}
                                    </Typography>


                                    {/* 
                            {orcamentos[index].st === 0 ? <Typography> Pedido Aprovado</Typography> :null} */}

                                </AccordionSummary>
                                <div style={{ border: "1px solid", margin: 10 }}>
                                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                        {edit !== orcamentosFiltrados[index].id ?
                                            <div>
                                                <Typography>
                                                    id : {orcamentosFiltrados[index].id}
                                                </Typography>
                                                <Typography>
                                                    Valor:{formatter.format(orcamentosFiltrados[index].valor)}
                                                </Typography>
                                                <Typography>
                                                    Geração Média:{orcamentosFiltrados[index].ger_media} kWh
                                                </Typography>

                                                <Typography>
                                                    Geração Desejada:{orcamentosFiltrados[index].geracao_desejada} kWh
                                                </Typography>

                                                <Typography>
                                                    Posição: {orcamentosFiltrados[index].posicao}
                                                </Typography>
                                                <Typography>
                                                    Local da Usina: {orcamentosFiltrados[index].local_usina === 0 ? 'Telhado' : 'Solo'}
                                                </Typography>
                                                <Typography>
                                                    Padrão: {orcamentosFiltrados[index].padrao}
                                                </Typography>
                                                <Typography>
                                                    Cidade: {orcamentosFiltrados[index].cidade}
                                                </Typography>
                                                <Typography>
                                                    Observações: {orcamentosFiltrados[index].obs}
                                                </Typography>
                                                <Typography>
                                                    Revisão :{orcamentosFiltrados[index].revisao}
                                                </Typography>

                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div>
                                                        <Button style={{ backgroundColor: '#12824C', color: '#FFFFFF' }} onClick={() => baixarOrcamento(orcamentosFiltrados[index].id, orcamentosFiltrados[index].revisao)}>  {orcamentosFiltrados[index].tipo_orcamento === 0 ? 'Baixar Orçamento Word' : 'Gerar orçamento Word'}</Button>

                                                    </div>
                                                    <div style={{ marginLeft: 20 }}>
                                                        <Button style={{ backgroundColor: '#12824C', color: '#FFFFFF' }} onClick={() => baixarOrcamentoPdf(orcamentosFiltrados[index].id, orcamentosFiltrados[index].revisao)}>  {orcamentosFiltrados[index].tipo_orcamento === 0 ? 'Baixar Orçamento PDF' : 'Gerar orçamento PDF'}</Button>

                                                    </div>

                                                    <div style={{ marginLeft: 20 }}>
                                                        {/* <Button style={{ backgroundColor: 'black', color: '#FFFFFF' }} onClick={() => (setEdit(orcamentosFiltrados[index].id), setArquivos(''), setSelectedBoard(orcamentosFiltrados[index].placas_id), setGeracaoDesejadaEx(orcamentosFiltrados[index].geracao_desejada), setLocalUsina(orcamentosFiltrados[index].local_usina), setPosicaoTelhado(orcamentosFiltrados[index].posicao === 'Norte' ? 0 : (orcamentosFiltrados[index].posicao === 'Nordeste/Noroeste' ? 1 : 2)))}>   Editar Orçamento</Button> */}
                                                        <Button style={{ backgroundColor: 'black', color: '#FFFFFF' }} onClick={() => handleEditOrcamento(orcamentosFiltrados[index])}>Editar Orçamento</Button>
                                                    </div>
                                                    <div style={{ marginLeft: 20 }}>
                                                        <Button
                                                            fullWidth
                                                            variant='contained'
                                                            //color='danger'
                                                            id="save"
                                                            onClick={() => {
                                                                deletaOrcamento(orcamentosFiltrados[index].id);
                                                            }}
                                                        >
                                                            Deletar Orçamento
                                                        </Button>
                                                    </div>


                                                </div>
                                                <Typography>
                                                    <div style={{ marginTop: 20 }}>
                                                        <Button style={{ backgroundColor: '#12824C', color: '#FFFFFF' }} onClick={() => getArquivos(orcamentosFiltrados[index].id)}>  Carregar Fotos</Button>

                                                    </div>
                                                    {arquivos !== '' ?
                                                        <div>
                                                            {arquivos.map((image, index) => (
                                                                <img style={{ width: 240, height: 240 }} src={image.file_64} key={index} />
                                                            ))}
                                                        </div> : (arquivos === '-1' ? 'Carregando fotos' : null)}
                                                </Typography>

                                            </div> :
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                                                <Typography>
                                                    id : {orcamentosFiltrados[index].id}
                                                </Typography>
                                                <Grid item xs={12} >
                                                    <FormControl
                                                        fullWidth                 >
                                                        <FormLabel id="radio-buttons-padrao-entrada">Posição do Telhado</FormLabel>
                                                        <RadioGroup
                                                            value={posicaoTelhado}
                                                            row
                                                            aria-labelledby="radio-buttons-padrao-entrada"
                                                            name="row-radio-buttons-group-padrao-entrada"
                                                        // onChange={ changePadraoEntreda } 
                                                        >
                                                            <Grid item xs={4} >
                                                                <Tooltip title="Telhado apontado para o norte">
                                                                    <FormControlLabel value="0" control={<Radio checked={posicaoTelhado === 0} />} label="Norte" onClick={() => (setPosicaoTelhado(0), getPlacas(geracaoDesejada))} />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Tooltip title="Telhado apontado para nordeste ou noroeste">
                                                                    <FormControlLabel value="1" control={<Radio checked={posicaoTelhado === 1} />} label="Nordeste/Noroeste" onClick={() => (setPosicaoTelhado(1), getPlacas(geracaoDesejada))} />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Tooltip title="Telhado apontado para o leste ou oeste">
                                                                    <FormControlLabel value="2" control={<Radio checked={posicaoTelhado === 2} />} label="Leste/Oeste" onClick={() => (setPosicaoTelhado(2), getPlacas(geracaoDesejada))} />
                                                                </Tooltip>
                                                            </Grid>
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <FormControl
                                                        fullWidth                 >
                                                        <FormLabel id="demo-row-radio-buttons-group-label">Local da Usina</FormLabel>
                                                        <RadioGroup
                                                            value={localUsina}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                        //onChange={ getTarifa } 
                                                        // onBlur={ changeTipoTarifa } 
                                                        >
                                                            <Grid item xs={6} >
                                                                <Tooltip title="Telhado">
                                                                    <FormControlLabel value="0" control={<Radio checked={localUsina === 0} />} onClick={() => changeLocalUsina(0)} label="Telhado" />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Tooltip title="Solo">
                                                                    <FormControlLabel value="1" control={<Radio checked={localUsina === 1} />} onClick={() => changeLocalUsina(1)} label="Solo" />
                                                                </Tooltip>
                                                            </Grid>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Typography>
                                                    Padrão: {orcamentosFiltrados[index].padrao}
                                                </Typography>
                                                <Typography>
                                                    Cidade: {orcamentosFiltrados[index].cidade}
                                                </Typography>

                                                <Typography>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Geração desejada"
                                                            defaultValue={orcamentosFiltrados[index].geracao_desejada}

                                                            onChange={e => setGeracaoDesejadaEx(e.target.value, index)}
                                                        />
                                                    </Grid>
                                                    {boards.length > 0 && (
                                                        <Grid item xs={12}>
                                                            <FormControl>
                                                                <FormLabel id="demo-radio-buttons-group-label">Opções</FormLabel>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue={selectedBoard}
                                                                    name="radio-buttons-group"
                                                                >
                                                                    {boards.length > 0 && boards.map((obj, index) => (
                                                                        <FormControlLabel value={obj.ID} control={<Radio checked={selectedBoard === obj.ID} />} label={`${obj.numero_de_placas} - ${obj.marca} (${obj.ger.toFixed(2)} kWh/mês) - R$ ${localUsina === 0 ? obj.valor.toFixed(2):obj.valor_solo.toFixed(2)}`} onClick={() => (setSelectedBoard(obj.ID),setSelectedPrice(localUsina === 0 ? obj.valor.toFixed(2):obj.valor_solo.toFixed(2)))} />

                                                                    ))
                                                                    }
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>)}
                                                </Typography>
                                                <Typography>
                                                    <Grid item xs={4}>
                                                        <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'row' }}>
                                                            <Button

                                                                variant='contained'
                                                                //color='danger'

                                                                id="save"
                                                                onClick={() => {
                                                                    atualizaOrcamento(orcamentosFiltrados[index]);
                                                                }}
                                                            >
                                                                {!atualizando ? 'Salvar Modificações' : 'Aguarde, Gerando Orçamento'}
                                                            </Button>
                                                            <div style={{ marginLeft: 20 }}>
                                                                <Button

                                                                    variant='contained'
                                                                    id="save"
                                                                    onClick={() => {
                                                                        setEdit(-1);
                                                                    }}
                                                                >
                                                                    Cancelar Edição
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Typography>

                                            </div>}
                                    </AccordionDetails>


                                </div>

                            </Accordion>
                        ) : null}

                </Grid>

            </div> : null
    )
}

export default GerenciaOrcamentos; 