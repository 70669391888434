import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";

import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { getUser, getUserInfo } from '../../utils/utils'

import { Table,TableBody,TableCell,TableContainer,
  TableHead,TableRow,TextField,Button,InputBase,InputAdornment,
  IconButton,CircularProgress,Grid,Divider,Modal,Box,Collapse,Paper ,
  Container,Radio,RadioGroup,FormControlLabel,FormControl,FormLabel,Tooltip} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";


const useStyles = makeStyles(styles);

export default function AdminNavbarLinks(props) {
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);


  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    localStorage.clear()
    
    window.location.replace('https://engiguacu.link')
  };
  const handleCloseProfileTeste = () => {

    console.log(1)
  };
  try{
    JSON.parse(getUserInfo())
  } catch (err) {
    
    localStorage.clear()
   console.log(1)
}
  return (
    <div >

      <div >
        <Button
          onClick={handleClickProfile}
          style={{ color: 'white', marginLeft: 50 }}
        >
          <p style={{ fontFamily: 'Poppins', color: '#383838', textTransform: 'none' }}>{JSON.parse(getUserInfo()).name.split(' ')[0]}</p>

          <PersonOutlineIcon style={{ color: '#383838' }} />

        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >

              <Paper>

                <MenuList role="menu">
                  <MenuItem
                    onClick={() => handleCloseProfileTeste()}
                    className={classes.dropdownItem}
                  >
                    Configurações de conta
                  </MenuItem>

                  <Divider light />
                  <MenuItem
                    onClick={() => handleCloseProfile()}
                    className={classes.dropdownItem}
                  >
                    Deslogar
                  </MenuItem>
                </MenuList>

              </Paper>


            </Grow>
          )}
        </Poppers>
      </div>
    </div >
  );
}
