import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import SidebarMin from "components/Sidebar/SidebarMin.js";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import StorageIcon from '@material-ui/icons/Storage';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TocIcon from '@material-ui/icons/Toc';
// paginas
import Estoques from 'views/Estoques/Estoques.js'

import GerarOrcamento from 'views/GerarOrcamento/GerarOrcamento.js'
import GerenciaOrcamentos from "views/GerarOrcamento/GerenciaOrcamentos";
import CadastroClientes from "views/GerarOrcamento/CadastroClientes";
import Configuracoes from "views/ConfigUsers/ConfigUsers";
import ConfiguracoesGerais from "views/ConfiguracoesGerais/ConfiguracoesGerais";


// icones
import Menu from "@material-ui/icons/Menu";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

import { getUserInfo } from '../utils/utils'
import {Button,
  IconButton} from '@material-ui/core';

import SettingsIcon from'@material-ui/icons/Settings';

import { useMediaQuery } from "@material-ui/core";

import logo from '../assets/img/logo.png'
import logo2 from '../assets/img/logo2.png'


let ps;




function returnThing(sideBar) {
  let dashboardRoutes = []
  let permissoes = getUserInfo()
  if (permissoes === ''){return dashboardRoutes}
  permissoes = (JSON.parse(permissoes))
  if (permissoes.clientes == 1) {
    dashboardRoutes.push(
      {
        path: "/cadastroclientes",
        name: sideBar === true ? "Cadastro Clientes" : '',
        icon: AssignmentIndIcon,
        component: CadastroClientes,
        layout: "/admin"
      })
  }
  
    dashboardRoutes.push(
      {
        path: "/Estoques",
        name: sideBar === true ? "Estoques " : '',
        icon: AssignmentIndIcon,
        component: Estoques,
        layout: "/admin"
      })
  
  if (permissoes.gerar_orcamento == 1) {
    dashboardRoutes.push(
      {
        path: "/GerarOrcamento",
        name: sideBar === true ? "GerarOrcamento" : '',
        icon: StorageIcon,
        component: GerarOrcamento,
        layout: "/admin"
      })
  }
  if (permissoes.orcamentos == 1) {
    dashboardRoutes.push(
      {
        path: "/gerenciamento",
        name: sideBar === true ? "Gerênciamento" : '',
        icon: TocIcon,
        component: GerenciaOrcamentos,
        layout: "/admin"
      })
  }
  if (permissoes.configuracoes == 1) {
    dashboardRoutes.push(
      {
        path: "/configuracoes",
        name: sideBar === true ? "Configurações Usuários" : '',
        icon: PeopleOutlineIcon,
        component: Configuracoes,
        layout: "/admin"
      })
  }
  if (permissoes.configuracoes_gerais == 1) {
    dashboardRoutes.push(
      {
        path: "/configuracoes_gerais",
        name: sideBar === true ? "Configurações Gerais" : '',
        icon: SettingsIcon,
        component: ConfiguracoesGerais,
        layout: "/admin"
      })
  }


  return dashboardRoutes
}


const switchRoutes = (
  <Switch>
    {returnThing().map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}


    <Redirect from="/admin" to="/admin/GerarOrcamento" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin(props, { ...rest }) {
  const [mostrarSidebar, setMostrarSidebar] = React.useState(false)
  // styles
  const classes = useStyles();
  const classes_bar = useStyles_bar()
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions

  const [color, setColor] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const smallerThan600px = useMediaQuery("(max-width: 600px)")

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });

      // async function fetchMyAPI() {
      //  const respose_dolar_hora = await api_amr.get('/dolarApi', { headers: { 'x-api-key': getToken() } });
      // setDolar(respose_dolar_hora.data)
      //console.log(respose_dolar_hora.data)
      // }
      // fetchMyAPI();

    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const AppBarCustom = () => {

    return (
      <div >
        <AppBar style={{ background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)' }}>

          <Toolbar >
            {mostrarSidebar ?
              <div style={{ position: 'fixed', left: 330, zIndex: 1030 }}> {/* 1030 porque no headerStyle é 1029 (é la que fica o style da barra de cima transparente)*/}
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setMostrarSidebar(!mostrarSidebar)}
                  style={{ color: '#383838' }}
                >
                  <Menu />
                </IconButton>

              </div> :
              <div style={{ marginLeft: smallerThan600px ? 0 : 80 }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setMostrarSidebar(!mostrarSidebar)}
                  style={{ color: '#383838' }}
                >
                  <Menu />
                </IconButton>
              </div>}



            <div style={{ width: '60vw' }}></div>



            <div className={classes_bar.grow} />

            <div className={classes_bar.sectionDesktop}>



              {/* caso o usuário não esteja logado o AdminNavbarLinks vai bugar tudo (é aqui o começo para resolver os problemas) */}
              {!smallerThan600px ?
                <>
                  {getUserInfo() === '' ?
                    <Button onClick={() => props.history.push({ pathname: "/" })}>
                      <div style={{ fontFamily: 'Poppins', color: '#383838', textTransform: 'none', display: 'flex', flexDirection: 'row' }}>
                        <PersonOutlineIcon style={{ color: '#383838', marginRight: 5 }} />
                        Login
                      </div>
                    </Button>
                    : <AdminNavbarLinks />}
                </>
                : null}

            </div>

          </Toolbar>
        </AppBar>


      </div >
    );
  }


  return (
    <div style={{ height: '100%' }}>
      <AppBarCustom />

      {mostrarSidebar ?
        <Sidebar
          routes={returnThing(mostrarSidebar)}
         logo={logo}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        /> : null}



      {smallerThan600px ? null : !mostrarSidebar ?
        <SidebarMin
          routes={returnThing(mostrarSidebar)}
          logo={logo2}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        /> : null}

      <div className={smallerThan600px ? null : mostrarSidebar ? classes.mainPanel : classes.mainPanel2} ref={mainPanel}>
        <Navbar
          routes={returnThing()}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

        <div className={classes.content}>
          <div >{switchRoutes}</div>
        </div>

      </div>
    </div>
  );
}

const useStyles_bar = makeStyles((theme) => ({
  grow: {
    height: 70,

  },
  menuButton: {
    marginRight: theme.spacing(2),

  }, inputhDolar: {
    fontFamily: 'Poppins',
    fontSize: 15,
    color: 'black'
  },
  search: {
    display: 'flex',
    flexDirection: 'row',
    width: 800,
    borderRadius: 22,
    backgroud: 'white',
    height: 35,
    boxShadow: '0px 9px 222px rgba(0, 0, 0, 0.07), 0px 3.75998px 92.7463px rgba(0, 0, 0, 0.0503198), 0px 2.01027px 49.5866px rgba(0, 0, 0, 0.0417275), 0px 1.12694px 27.7978px rgba(0, 0, 0, 0.035), 0px 0.598509px 14.7632px rgba(0, 0, 0, 0.0282725), 0px 0.249053px 6.14331px rgba(0, 0, 0, 0.0196802)',
    alignItems: 'center',
    marginLeft: 80,
    paddingLeft: 10
  },
  searchIcon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#383838',
  },
  inputRoot: {
    color: 'inherit',
    width: 800,
  }, inputh1: {
    fontFamily: 'Poppins',
    color: '#383838',
  },
  inputInput: {
    width: 800,
  },
  sectionDesktop: {
    display: 'flex',
  },

}));