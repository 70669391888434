import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import api from '../../services/api'
import Loader from 'react-loader-spinner';
import { checarPermissao, getToken, getUser } from '../../utils/utils'
import { Table,TableBody,TableCell,TableContainer,
    TableHead,TableRow,TextField,Button,InputBase,InputAdornment,IconButton,CircularProgress ,Switch} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';


function Configuracoes(props) {

    const [permitido, setPermitido] = useState(true)
    const [nomeCadastro, setNomeCadastro] = useState('')
    const [senha, setSenha] = useState('')
    const [senha2, setSenha2] = useState('')
    const [login, setlogin] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const [users, setUsers] = useState('')
    const [selectedUser, setSelectedUser] = useState('')
    const [usersArray, setUsersArray] = useState([])
    const [tab,setTab] = useState(0)

    const [admUserSelected, setAdmUserSelected] = useState(null)

    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {
            if (getUser === '') {
                window.location.replace('https://engiguacu.link')
            }
            setPermitido(await checarPermissao('configuracoes', props))

            const response_users = await api.get('/get_user_info', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });
            setUsers(response_users.data);
            setUsersArray(Array.from({ length: response_users.data.length }, i => i = false))



        }
        fetchMyAPI();
    }, []);


    async function cadastraUsuario() {
        if (senha !== senha2) {
            alert('Senhas diferentes')
            return
        }

        if ((senha === '' | login === '' | nomeCadastro === '')) {
            alert('Alguns campos obrigatórios estão incompletos\n' + (nomeCadastro === '' ? 'Nome\n' : '')
                + (login === '' ? 'Login\n' : '') + (senha === '' ? 'Senha\n' : ''))
            return
        }


        const response_create = await api.post('/create_user',
            {
                'login': login, 'password': senha, 'name': nomeCadastro
            }, { headers: { 'x-api-key': getToken() } }
        );
        if (response_create.data === 'OK') {
            alert('Usuário adicionado com sucesso!')
            window.location.reload()
        }

    }
    // async function atualizaUsuariobyADM() {
    //     if ((nomeCadastro === '') && (cpfCnpj === '') && (telefone === admUserSelected.cellphone) && (cep === '') && (cidade === '') && (bairroSetor === '') && (end === '') && (senha === '') && (senha2 === '') && (grupo==='') && (seg==='')) {
    //         alert("Nenhum dado foi alterado!")
    //         return
    //     }
    //     if (senha != senha2) {
    //         alert('Senhas diferentes')
    //         return
    //     }
    //     if (!validateCPF(cpfCnpj) && !validateCNPJ(cpfCnpj) && cpfCnpj !== '') {
    //         alert('CPF/CNPJ Inválido')
    //         return
    //     }
    //     if (!validateCEP(cep) && cep !== '') {
    //         alert('CEP Inválido')
    //         return
    //     }

    //     setAtualizando(true)
    //     const response_atualiza_informacoes = await api.post('/update_user', { 'id': (admUserSelected !== null ? admUserSelected.id : ''), 'nome': nomeCadastro, cpfCnpj, telefone, cep, 'id_cidade': (cidade != '' ? cidade.id : ''), 'bairro_setor': bairroSetor, 'endereco': end, 'password': senha, 'grupo':(grupo != '' ? grupo.id : ''), 'seg': (seg != '' ? seg.id : '') }, { headers: { 'x-api-key': getToken() } })
    //     console.log(response_atualiza_informacoes.data)
    //     if ((response_atualiza_informacoes.data === 'OK1')) {
    //         alert('Dados atualizados')
    //         window.location.reload()

    //     } else {
    //         alert('Erro ao atualizar dados')
    //     }
    //     setAtualizando(false)
    // }


    async function alterSelected(index) {
        let aux = Array.from({ length: usersArray.length }, i => i = false)
        aux[index] = true
        setUsersArray(JSON.parse(JSON.stringify(aux)))
        setSelectedUser(index)


    }
    async function atualizaPermissao(value, tela, id) {

        const response_create = await api.get('/update_permission',
            { params: { 'perm': value, 'page': tela, 'login': id }, headers: { 'x-api-key': getToken() } });


        console.log(response_create.data)

    }


    return (
        permitido ?
            <div className={classes.container} style={{marginLeft:12}}>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <Button className={classes.inputh1} onClick={() => setTab(0)}>Cadastro Usuário</Button>
                    <Button className={classes.inputh1} onClick={() => setTab(1)}>Permissões</Button>
                </div>

                <div >
                    <h2 className={classes.inputh1}>{admUserSelected !== null ? "Atualizar Dados do Cliente" : "Cadastrar Usuário"}</h2>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '80vw' }}>
                    {tab === 0 ?
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', borderRadius: 10, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: "white", padding: 20, border: '1px solid black' }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
                                <TextField
                                    key='nome'
                                    placeholder={admUserSelected !== null ? admUserSelected.name : ''}
                                    variant="filled"
                                    onChange={(event) => setNomeCadastro(event.target.value)}
                                    value={nomeCadastro}


                                    label={admUserSelected !== null ? 'Nome' : 'Nome Usuário*'}

                                />



                            </div>
                            <form autoComplete={"off"}>
                                <TextField
                                    key='login'
                                    variant="filled"
                                    placeholder={admUserSelected !== null ? admUserSelected.login : ''}

                                    onChange={(event) => setlogin(event.target.value)}
                                    value={admUserSelected !== null ? admUserSelected.login : login}


                                    label="login*"

                                />
                            </form>
                            <div>
                                <InputBase
                                    key='senha'
                                    variant="filled"
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={senha}
                                    autoComplete='new-password'
                                    placeholder='Senha'
                                    onChange={(event) => setSenha(event.target.value)}



                                    style={{ height: 50, paddingLeft: 15 }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(true)}
                                                onMouseDown={() => setShowPassword(false)}
                                            >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <InputBase
                                    variant='outlined'
                                    id="standard-adornment-password2"
                                    type={showPassword ? 'text' : 'password'}
                                    value={senha2}
                                    placeholder='Redigite a senha'
                                    onChange={(event) => setSenha2(event.target.value)}

                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { notchedOutline: classes.noBorder }
                                    }}
                                    style={{ height: 50, paddingLeft: 15, marginLeft: 15 }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(true)}
                                                onMouseDown={() => setShowPassword(false)}
                                            >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>

                            <div >
                                <Button onClick={() => (admUserSelected !== null ? null : cadastraUsuario())} variant="contained" > {admUserSelected !== null ? "Atualizar Dados do Usuario" : "Cadastrar"}</Button>
                            </div>


                        </div> : null}
                    {tab === 1 ?
                        <div style={{ display: 'flex', flexDirection: 'column', width: '70vw', height: '50vh', overflow: 'auto' }}>
                            <b className={classes.inputh1}>Usuários Cadastrados</b>

                            {users !== '' ? <TableContainer className={classes.tableModels}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={50}>

                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Nome</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>login</b>
                                            </TableCell>
                                            <TableCell width={50}>

                                                <b className={classes.inputh1}>Página Clientes</b>
                                            </TableCell>
                                            <TableCell width={50}>
                                                <b className={classes.inputh1}>Página Gerar Orçamento</b>
                                            </TableCell>
                                            <TableCell width={150}>
                                                <b className={classes.inputh1}>Página Gerenciar Orçamentos</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Página Configuração de usuários</b>
                                            </TableCell>
                                            <TableCell width={100}>
                                                <b className={classes.inputh1}>Página Configurações Gerais</b>
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(users).map((item, index) => (
                                            <TableRow key={index} style={{ backgroundColor: usersArray[index] === true ? 'white' : 'white' }} >
                                                <TableCell >

                                                    {usersArray[index] === true ? <IconButton
                                                        
                                                        aria-label="toggle password visibility"
                                                        onClick={() => alterSelected(index)}
                                                    >
                                                        < CheckIcon />
                                                    </IconButton> : <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => alterSelected(index)}

                                                    >
                                                        <RadioButtonUncheckedIcon />
                                                    </IconButton>}


                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{users[item].name}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <b className={classes.inputh1}>{users[item].login}</b>
                                                </TableCell>
                                                <TableCell  >
                                                    <Switch
                                                        defaultChecked={users[item].clientes === 0 ? false : true}
                                                        size="small"
                                                        onChange={(e) => atualizaPermissao(e.target.checked, 'clientes', users[item].login)} />

                                                </TableCell>
                                                <TableCell  >
                                                    <Switch
                                                        defaultChecked={users[item].orcamentos === 0 ? false : true}
                                                        size="small"
                                                        onChange={(e) => atualizaPermissao(e.target.checked, 'orcamentos', users[item].login)} />


                                                </TableCell>
                                                <TableCell  >
                                                    <Switch
                                                        defaultChecked={users[item].gerar_orcamento === 0 ? false : true}
                                                        size="small"
                                                        onChange={(e) => atualizaPermissao(e.target.checked, 'gerar_orcamento', users[item].login)} />

                                                </TableCell>
                                                <TableCell  >
                                                    <Switch
                                                        defaultChecked={users[item].configuracoes === 0 ? false : true}
                                                        size="small"
                                                        onChange={(e) => atualizaPermissao(e.target.checked, 'configuracoes', users[item].login)} />

                                                </TableCell>
                                                <TableCell  >
                                                    <Switch
                                                        defaultChecked={users[item].configuracoes_gerais === 0 ? false : true}
                                                        size="small"
                                                        onChange={(e) => atualizaPermissao(e.target.checked, 'configuracoes_gerais', users[item].login)} />

                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> : <CircularProgress />}

                        </div> : null}

                </div>
            </div> : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    PhoneInput: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },

    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '90%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
}));

export default Configuracoes;
